// These are styles that will likely either be refactored here
// or bubbled up to blueprint once we have more instances of
// this pattern repeating itself

@media (min-width: 576px) {
  .button-label-offset {
    margin-top: 3.2rem;
  }
}

// we didn't take into account people not using
// the mm logo but their own in the navigation patterns
.mm-navbar--transaction .mm-logo {
  background-image: url("../images/ServiceNetLogoBlue.svg");
  height: 2.1rem;
}

// for buttons in headers
img {
  max-width: 100%;
}



.react-datepicker__tab-loop .react-datepicker-popper {
  z-index: 2;
}



.mm-list-group--quick-actions li button,
.mm-list-group--quick-actions li .btn-inline {
  &.download {

    &::after,
    &:hover::after {
      background-image: url("../../node_modules/blueprint-css/images/icons/actions/download.svg");
    }
  }

  &.loading {

    &::after,
    &:hover::after {
      background-image: url("../../node_modules/blueprint-css/images/mm-logo/mm_dots_loader.svg");
    }
  }
}

.skip-link {
  &:not(:focus) {
    @extend .sr-only;
  }
}


// navbar notifcations. This could all be deleted if we just upgrade blueprint and use the one
// that is included in there. Mainly because absolute positioning shouldn't be used on mobile...

// so dropdowns line up correctly on small screens
.navbar-nav {
  flex-direction: row;
}

.dropdown-menu.notifications {
  // having enough space to show notifications
  @media (min-width: 992px) {
    min-width: 50rem;
    max-height: 40rem;
  }

  // fixing for mobile. again should not be using component
  // with absolute positioning here...
  @media (max-width: 991.98px) {
    width: 95vw;
    height: 80vh;
  }
}

.mm-navbar--auth .navbar .dropdown {
  &.show .dropdown-toggle {
    background: #001735;
    border-radius: .8rem;
  }

  .dropdown-toggle {
    padding-left: .8rem;
    padding-right: .8rem;

    &, &:hover {
      border: 0 !important;
    }
  }
}

.dropdown-item {
  white-space: normal;
}

// because we're not doing a collapse...
.mm-navbar--auth .navbar {
  .navbar-nav .nav-link {
    .icon-notification-white {
      margin-right: 0
    }

    // because we're not using correct blueprint nav,
    // have to position badge
    .badge-indicator {
      position: absolute;
      left: 0;
      top: .8rem;
      padding: .3rem;

      @media screen and (max-width: 991.98px) {
        top: 1rem;
        left: .8rem;
      }
    }
  }

  // no collapse, so keep consistent between media
  .dropdown-menu {
    background: #fff;
    padding: .8rem;
    box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.05), 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.13);

    .dropdown-item {
      color: rgba(0, 0, 0, .85);

      &:hover {
        text-decoration: none;
        background: rgb(255, 255, 255)
      }

      .badge-warning {
        color: #fff;
        margin-bottom: .4rem;
      }

      .col-8 p {
        font-family: "Lato", Helvetica, Arial, sans-serif;
        font-weight: 400;

        &:first-child {
          margin-left: 1.4rem;
        }
      }

      .copy-sm {
        color: #004DB2;
      }

      a {
        text-decoration: none;

        :hover {
          text-decoration: underline;
        }
      }

      span {
        color: #000000;
      }
    }
  }

  // text before and after notification list
  .dropdown-text {
    padding: 0.8rem 1.6rem;

    @media screen and (max-width: 991.98px) {
      padding: .8rem;
    }

    hr {
      margin: 0;
    }
  }
}

.SN-notification-wrapper {
  @media (min-width: 768px) {
    text-align: center;
    min-height: 18rem;
    padding-top: 2rem;

    p.h4 {
      margin-bottom: 0;
    }
  }
}

// end because no collapse...

// end navbar notifications

@media print {
  nav,
  .hide-print,
  .sr-only,
  .skip-link,
  .sn-scrollspy_navigation,
  .mm-scrollspy-btn-expand,
  #quick_actions_desktop,
  #quick_actions_mobile,
  #kpis,
  #service_history_section,
  #documents_section,
  #baltoRoot {
    display: none;
  }

  .show-print {
    display: block;
  }

  body {
    font-size: 12px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 14px;
  }

  button {
    border: 0;
    background: none;
    padding: 0;
    font-size: 12px;
    font-family: inherit;
    text-align: left;
  }

  table {
    page-break-inside: avoid;
    border-bottom: 1px solid #c1c9d5;
    margin-bottom: 20px;
    border-spacing: 0;
  }

  th {
    text-align: left;
  }

  th,
  td {
    border-top: 1px solid #c1c9d5;
    padding: 8px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    p {
      margin: 0;
    }
  }

  dl,
  .row {
    grid-gap: 8px;
    display: grid;
    grid-template-columns: [key] 1fr [value] 1fr;
    max-width: 8in;
  }

  .row {
    width: max-content;
  }

  dl {
    width: fit-content;

    dt {
      font-weight: bold;
    }

    // agents list
    button {
      display: block;
    }
  }

  ol,
  ul {
    padding: 0 0 0 15px;
  }

  a {
    //regular link
    text-decoration: none;
    color: inherit;

    // "Request Information" -> "Unavailable"
    &.p {
      visibility: hidden;
      color: black;
      text-decoration: none;

      &:before {
        content: "Unavailable";
        display: inline;
        visibility: visible;
      }
    }
  }

  .mm-collapse .h3 {
    display: block;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  // people on policy checkmark substitution
  [class*="icon-check"] {
    &:before {
      content: "X";
      font-weight: bold;
      display: block;
      text-align: center;
    }
  }

  #print_disclosure {
    font-size: 14px;
    font-weight: bold;
  }

  // this can probably be refactored later. we didn't take into account people not using
  // the mm logo but their own in the navigation patterns
  .mm-navbar--transaction .mm-logo {
    background-image: url("images/ServiceNetLogoBlue.svg");
    background-repeat: no-repeat;
    height: 2.1rem;
  }

  // legal footer
  .mm-footer-legal {
    margin-top: 2.4rem;
  
    .row {
      display: block;
    }
  
    .mm-logo {
      background-image: url("../node_modules/blueprint-css/images/mm-logo/MM_FullMark.svg");
      background-repeat: no-repeat;
      width: 182px;
      height: 22px;
      print-color-adjust: exact;
    }
  
    ul {
      display: none;
    }
  }
}

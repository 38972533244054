.sn-scroll {
  max-height: 40rem;

  .mm-table thead {
    position: sticky;
    top: 0;
    z-index: 2;
    background: #fff;

    th .col-header {
      min-width: auto;
    }
  }

  th {
    box-shadow: 0 0.3rem 0.6rem -0.6rem rgba(#000, 0.15);
  }

  @media (max-width: 767.98px) {
    .mm-table th:first-child,
    .mm-table td:first-child {
      min-width: auto;
    }
  }
}

.mm-table {
  .btn.btn-inline {
    /* documents buttons */
    display: inline-flex;
    align-items: center;

    /* quick actions button */
    margin: 0;
  }

  /* removed in last blueprint table update */
  thead th .col-header {
    min-width: 13rem;
  }

  /* sorting icons */
  .btn.btn-link {
    max-height: 3.7rem;

    [class*="icon"] {
      margin-top: -.7rem;
    }
  }
}

/* removed from blueprint in v6 */
.mm-table-figures {
  @extend .table, .table-borderless;

  &:not(:last-child)  { margin-bottom: 3.2rem; }

  td {
    padding: 0 .8rem;
    vertical-align: bottom;
    @extend .copy-lg;

    &:not(:first-child) { text-align: right; }
  }

  tfoot td {
    padding: .8rem .8rem 0;
    @extend .h4;
  }

  td,
  tfoot td {
    &:first-child { padding-left: 0; }
    &:last-child  { padding-right: 0; }
  }
}

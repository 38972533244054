button.btn-inline:not(:hover):not(:active):not(:focus) .icon-more {
  // this is just a straight copy of blueprint's midnight-blue filter since we can't extend it
  // #002f6c
  filter: brightness(0) saturate(100%) invert(14%) sepia(21%) saturate(7491%)
    hue-rotate(201deg) brightness(97%) contrast(103%);
  -webkit-filter: brightness(0) saturate(100%) invert(14%) sepia(21%)
    saturate(7491%) hue-rotate(201deg) brightness(97%) contrast(103%);
}

.icon {
  &-help,
  &-info {
    &:hover {
      cursor: pointer;
    }
  }
}

.mm-scrollspy {
  .sn-scrollspy_content {
    padding-bottom: 55rem;
  }
}

@media (min-width: 992px) {
  .mm-scrollspy {
    .sn-scrollspy_navigation .mm-scrollspy-list-group {
      top: 8.4rem;
    } // 2.4rem base + 6rem for header offset

    section {
      scroll-margin-top: 8.4rem; // header offset
    }

    h3 {
      scroll-margin-top: 8.4rem; // header offset
    }
  }
}

@media (max-width: 991.98px) {
  .mm-scrollspy {
    section {
      scroll-margin-top: 2.4rem;
    }

    h3 {
      scroll-margin-top: 2.4rem;
    }

    // necessary because we don't do our header correctly
    // can be removed when we move the links inside a hamburger collapse
    &.main.nav-auth {
      .mm-scrollspy-btn-expand, 
      .mm-scrollspy-list-group { 
        top: 8rem;
      }
    }
  }
}

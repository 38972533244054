.text-danger {
  color: var(--bp-color-alert-danger);
}

// buttons in headers, removed from blueprint per design
h1, h2, h3,
.h1, .h2, .h3 {
  .btn.btn-inline {
    font-size: 1.6rem;
    font-weight: 600;
    letter-spacing: normal;
    margin-left: 1.6rem;
  }
}
// end buttons in headers

// business typopgraphy styles, removed from blueprint per design
h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 { 
  .badge {
    vertical-align: middle;
    margin-left: 2.4rem;
  }

  + .mm-sub-copy {
    /* %copy-sm */
    font-family: "Lato", Helvetica, Arial, sans-serif;;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;
    color: var(--bp-color-text-primary);
    letter-spacing: normal;
  }
}

// Heading Sub-copy Margin
h1, .h1 { + .mm-sub-copy  { margin: -(.8rem) 0 2.4rem; }}
h2, .h2 { + .mm-sub-copy  { margin: -(1.6rem) 0 3.2rem; }}
h3, .h3 { + .mm-sub-copy  { margin: -(1.6rem) 0 2.4rem; }}
h4, .h4 { + .mm-sub-copy  { margin: -(.8rem) 0 1.6rem; }}
h5, .h5 { + .mm-sub-copy  { margin: -(.8rem) 0 1.6rem; }}
// end business typopgraphy styles

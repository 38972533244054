@media screen {
  // this is not the *correct* way to do this. the correct way would be
  // to do something like this in App.js: 
  // // eslint-disable-next-line import/no-extraneous-dependencies
  // import "blueprint-css/mm-business/mm-business.bundle.css";
  // right before we import "./App.scss";
  // *but* that would apply styles to print and screen so we have to
  // do this hack instead which may break in the future...
  @import "../node_modules/blueprint-css/mm-business/mm-business.bundle";
  @import "sass/typography";
  @import "sass/scrollspy";
  @import "sass/icons";
  @import "sass/tables";
  @import "sass/spacing";
  @import "sass/refactor";

  .print-only {
    display: none;
  }
}
